import React, { useState } from 'react';
import { GoogleMap, Marker, useJsApiLoader, InfoBox } from '@react-google-maps/api'; // https://react-google-maps-api-docs.netlify.app/
import LocationIcon from '../../content/icons/location.svg';
import SettingsHelper from '../../helpers/SettingsHelper';
import { Spinner } from 'reactstrap';
import { FormattedMessage } from 'react-intl';

export type MapContainerProps = {
    latitude: number;
    longitude: number;
    showMarker?: boolean;
    showInfoBox?: boolean;
    infoWindowContent?: string;
    onLatLngChange?: (e: google.maps.LatLng | google.maps.LatLngLiteral) => void;
    onCloseClick?: () => void;
    onDragEnd?: (latLng: { lat: number; lng: number }) => void;
};

function MapContainer({
    latitude,
    longitude,
    showMarker = true,
    showInfoBox,
    infoWindowContent,
    onLatLngChange,
    onCloseClick,
    onDragEnd,
}: MapContainerProps) {
    const { isLoaded, loadError } = useJsApiLoader({
        googleMapsApiKey: SettingsHelper.getGoogleApiBrowserKey() ?? '',
    });
    const [markerLatLng, setMarkerLatLng] = useState<google.maps.LatLng | google.maps.LatLngLiteral>({
        lat: latitude,
        lng: longitude,
    });
    const [mapLatLng] = useState<google.maps.LatLng | google.maps.LatLngLiteral>({
        lat: latitude,
        lng: longitude,
    });

    const onLatLngChangeInternal = (e: google.maps.LatLng | google.maps.LatLngLiteral | null) => {
        if (e != null) {
            setMarkerLatLng(e);
            onLatLngChange && onLatLngChange(e);
        }
    };

    const onDragEndInternal = (e: google.maps.LatLng | google.maps.LatLngLiteral | null) => {
        if (typeof e?.lat === 'function' && typeof e?.lng === 'function') {
            onDragEnd && onDragEnd({ lat: e.lat(), lng: e.lng() });
        } else if (typeof e?.lat === 'number' && typeof e?.lng === 'number') {
            onDragEnd && onDragEnd({ lat: e.lat, lng: e.lng });
        }
    };

    const getMap = () => {
        return (
            <GoogleMap
                zoom={18}
                center={mapLatLng}
                options={{
                    styles: [{ elementType: 'labels', featureType: 'poi.business', stylers: [{ visibility: 'off' }] }],
                }}
                mapContainerClassName="map-container"
            >
                {showMarker && (
                    <Marker
                        draggable
                        onDrag={(e) => onLatLngChangeInternal(e.latLng)}
                        position={markerLatLng}
                        icon={LocationIcon}
                        onDragEnd={(e) => onDragEndInternal(e.latLng)}
                    ></Marker>
                )}
                {showInfoBox && (
                    <InfoBox
                        options={{
                            pixelOffset: new window.google.maps.Size(-127.5, -70),
                            alignBottom: true,
                            maxWidth: 250,
                            boxStyle: {
                                width: '250px',
                                border: '1px solid #059dd0',
                                borderRadius: '5px',
                                boxShadow: '0px 0px 12px #d2d2d2',
                            },
                        }}
                        onCloseClick={onCloseClick}
                        position={markerLatLng}
                    >
                        <div style={{ backgroundColor: 'white', padding: 12 }}>
                            <div style={{ fontSize: 16 }}>{infoWindowContent}</div>
                        </div>
                    </InfoBox>
                )}
            </GoogleMap>
        );
    };

    if (loadError) {
        return (
            <div>
                <FormattedMessage id="OnboardingWizard.MapCannotBeLoaded" />
            </div>
        );
    }

    return isLoaded ? getMap() : <Spinner />;
}

export default MapContainer;
